.left-menu {
    width: 160px;
    border-radius: 0 0 5px 0;
    background-color: white;
    float: left;
    margin-right: 30px;
    box-shadow: 2px 2px 10px rgb(220, 220, 220);
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .left-menu.nav {
        display: none;
    }
}

a.nav-link {
    text-align: left;
    color: black;
    padding: 0 20px;
}

.nav ul {
    margin: 0;
}

ul a.nav-link {
    padding-left: 0px;
}

.right-navbar.navbar {
    padding: 0;
}

@media screen and (min-width: 600px) and (max-width: 920px) {
    .right-navbar.navbar.navbar {
        position: absolute;
    }

    .langImg {
        top: 50px;
        left: 235px;
    }
}
