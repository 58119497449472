.add-bill-container {
    width: 350px;
    display: block;
    margin: auto;
}

.add-bill-form-control, .add-cost-form-control {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
}

.bill-list-container {
    box-sizing: border-box;
    margin: auto;
    width: 300px;
}

.bill-list-row {
    display: block;
}

.bill-list-row-container {
    display: inline-block;
}

.interval-options-container {
    display: block;
    padding: 10px;
    margin: auto;
    text-align: center;
    margin-bottom: 25px;
}

.interval {
    font-weight: 400;
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
}

.selected-interval {
    color: #FF2299;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
}

.delete-btn {
    display: inline-block;
    width: 100px;
    text-align: right;
    cursor: pointer;
}

.edit-mode-btn, .add-bill-container button[type='submit'] {
    cursor: pointer;
    width: fit-content;
    background-image: linear-gradient(to right, #94FECB 50%, #94FECB 50%, #FF2299);
	background-size: 200%;
    border-radius: .5rem;
    padding: 10px 15px;
    border: none;
    transition: 0.4s;
}

.edit-mode-btn:hover, .add-bill-container button[type='submit']:hover {
    background-position: 100%;
	color: #FFF;
}

.add-bill-container .brands-select {
    width: 100%;
    border-radius: .3rem;
}

.add-bill-container button[type='submit'] {
    width: 350px;
    text-align: center;
}

.edit-mode-btn.edit {
    margin: auto;
    margin-bottom: 1rem;
}

.delete-btn:hover, .edit-mode-btn:hover {
    background-position: 100%;
    color: white;
}

.edit-bill-title {
    width: 100px;
    display: inline-block;
}

.edit-bill-cost-input {
    width: 200px;
    display: inline-block;
}

.bill-total-container,
.total-saved-container {
    width: 350px;
    display: block;
    margin: auto;
}