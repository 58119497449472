.start-page {
    min-height: 100vh;
}

.start-page h1 {
    font-size: 24px;
    margin-top: 20px;
}

.right-menu {
    border-radius: .5rem;
    box-shadow: 1px 1px 7px #ccc;
    display: inline-block;
    float: right;
}

.right-menu .d-flex {
    flex-direction: column;
}

.right-menu h2 {
    font-size: 1rem;
}

.right-menu input {
    border-radius: .5rem;
    border-color: rgb(243, 243, 243);
    border-style: solid;
}

.right-menu button {
    border-radius: .5rem;
    background-image: linear-gradient(to right, #94FECB 50%, #94FECB 50%, #FF2299);
	background-size: 200%;
    border-color: rgb(243, 243, 243);
}

.right-menu button:hover {
	background-position: 100%;
	color: #FFF;
}

.left-box {
    max-width: 900px;
}

.start-page img {
    max-width: 100%;
}

@media screen and (max-width: 600px) {
    #right  {
        float: none;
        display: block;
    }

    .right-navbar {
        position: absolute!important;
        top: 0;
        left: 0;
    }
}