.App {
  background-image: url("/public/media/pngegg.png");
  background-repeat: repeat;
  background-size: 30%;
  background-color: #cccccc;
  min-height: 100vh;
}

.App .container {
  padding-right: 0px;
  padding-left: 0px;
}

.home {
  min-height: 80vh;
  max-width: 920px;
}

.footer {
  text-align: center;
  padding: 20px;
}

.langImg {
  position: absolute;
  top: 10px;
  left: 180px;
}

@media screen and (max-width: 600px){
  h1 {
    font-size: smaller;
  }

  .langImg {
    top: 50px;
    left: 230px;
  }
}

.ua {
  border-radius: 50%;
}

button.exit:hover {
  background-color: yellow;
}

